import React from "react";

// Lazy load accountant components
export const JournalReport = React.lazy(() =>
  import("./accountant/JournalReport")
);
export const AccountTransactions = React.lazy(() =>
  import("./accountant/AccountTransactions")
);
export const AccountTypeSummary = React.lazy(() =>
  import("./accountant/AccountTypeSummary")
);
export const DetailedGeneralLedger = React.lazy(() =>
  import("./accountant/DetailedGeneralLedger")
);
export const GeneralLedger = React.lazy(() =>
  import("./accountant/GeneralLedger")
);
export const TrialBalance = React.lazy(() =>
  import("./accountant/TrialBalance")
);
export const Reports = React.lazy(() => import("./Reports"));

// Lazy load business overview components
export const BalanceSheet = React.lazy(() =>
  import("./business-overview/BalanceSheet")
);
export const ProfitAndLoss = React.lazy(() =>
  import("./business-overview/ProfitAndLoss")
);
export const CashFlowReport = React.lazy(() =>
  import("./business-overview/CashFlowReport")
);
export const MovementOfEquity = React.lazy(() =>
  import("./business-overview/MovementOfEquity")
);

// Lazy load sales components
export const SalesByCustomer = React.lazy(() =>
  import("./sales/SalesByCustomer")
);
export const SalesBySalesPerson = React.lazy(() =>
  import("./sales/SalesBySalesPerson")
);
export const SalesByProduct = React.lazy(() =>
  import("./sales/SalesByProduct")
);

// Lazy load inventory components
export const ProductSalesReport = React.lazy(() =>
  import("./inventory/ProductSalesReport")
);
export const InventoryValuationSummary = React.lazy(() =>
  import("./inventory/InventoryValuationSummary")
);
export const StockSummaryReport = React.lazy(() =>
  import("./inventory/StockSummaryReport")
);
export const InventorySummary = React.lazy(() =>
  import("./inventory/InventorySummary")
);
export const InventoryValuationByItem = React.lazy(() =>
  import("./inventory/InventoryValuationByItem")
);
export const WarehouseReport = React.lazy(() =>
  import("./inventory/WarehouseReport")
);

// Lazy load payables components
export const APAgingSummary = React.lazy(() =>
  import("./payables/APAgingSummary")
);
export const PayableSummary = React.lazy(() =>
  import("./payables/PayableSummary")
);
export const PayableDetails = React.lazy(() =>
  import("./payables/PayableDetails")
);
export const PurchaseOrderDetails = React.lazy(() =>
  import("./payables/PurchaseOrderDetails")
);
export const SupplierBalanceDetails = React.lazy(() =>
  import("./payables/SupplierBalanceDetails")
);
export const SupplierBalanceSummary = React.lazy(() =>
  import("./payables/SupplierBalanceSummary")
);
export const BillsDetails = React.lazy(() => import("./payables/BillsDetails"));
export const APAgingDetails = React.lazy(() =>
  import("./payables/APAgingDetails")
);
export const SupplierCreditDetails = React.lazy(() =>
  import("./payments-made/SupplierCreditDetails")
);

// Lazy load receivables components
export const InvoiceDetails = React.lazy(() =>
  import("./receivables/InvoiceDetails")
);
export const SalesOrderDetails = React.lazy(() =>
  import("./receivables/SalesOrderDetails")
);
export const CustomerBalanceDetails = React.lazy(() =>
  import("./receivables/CustomerBalanceDetails")
);
export const CustomerBalanceSummary = React.lazy(() =>
  import("./receivables/CustomerBalanceSummary")
);
export const ReceivableSummary = React.lazy(() =>
  import("./receivables/ReceivableSummary")
);
export const ReceivableDetails = React.lazy(() =>
  import("./receivables/ReceivableDetails")
);
export const ARAgingSummary = React.lazy(() =>
  import("./receivables/ARAgingSummary")
);
export const ARAgingDetails = React.lazy(() =>
  import("./receivables/ARAgingDetails")
);

// Lazy load expense components
export const ExpenseDetails = React.lazy(() =>
  import("./expense/ExpenseDetails")
);
export const ExpenseByCategory = React.lazy(() =>
  import("./expense/ExpenseByCategory")
);
export const ExpenseDetailsByCategory = React.lazy(() =>
  import("./expense/ExpenseDetailsByCategory")
);

// Lazy load payments components
export const PaymentsReceivedReport = React.lazy(() =>
  import("./payments-received/PaymentsReceivedReport")
);
export const PaymentsMadeReport = React.lazy(() =>
  import("./payments-made/PaymentsMadeReport")
);
export const CustomerRefundHistory = React.lazy(() =>
  import("./payments-received/CustomerRefundHistory")
);
export const SupplierRefundHistory = React.lazy(() =>
  import("./payments-made/SupplierRefundHistory")
);

// Lazy load purchase components
export const PurchaseBySupplier = React.lazy(() =>
  import("./purchase/PurchaseBySupplier")
);
export const PurchaseByProduct = React.lazy(() =>
  import("./purchase/PurchaseByProduct")
);

// Lazy load currency components
export const RealizedGainOrLoss = React.lazy(() =>
  import("./currency/RealizedGainOrLoss")
);
export const UnrealizedGainOrLoss = React.lazy(() =>
  import("./currency/UnrealizedGainOrLoss")
);

// Lazy load report layout component
export const ReportLayout = React.lazy(() => import("./ReportLayout"));

export const CreditNoteDetails = React.lazy(() =>
  import("./payments-received/CreditNoteDetails")
);
