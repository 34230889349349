import React from "react";

// Lazy load financial transaction components
export const OwnerDrawingsNew = React.lazy(() => import("./OwnerDrawingsNew"));
export const TransferFromAnotherAccNew = React.lazy(() =>
  import("./TransferFromAnotherAccNew")
);
export const TransferFromAnotherAccEdit = React.lazy(() =>
  import("./TransferFromAnotherAccEdit")
);
export const TransferToAnotherAccNew = React.lazy(() =>
  import("./TransferToAnotherAccNew")
);
export const TransferToAnotherAccEdit = React.lazy(() =>
  import("./TransferToAnotherAccountEdit")
);
export const OwnerContributionNew = React.lazy(() =>
  import("./OwnerContributionNew")
);
export const SupplierAdvanceNew = React.lazy(() =>
  import("./SupplierAdvanceNew")
);
export const CustomerAdvanceNew = React.lazy(() =>
  import("./CustomerAdvanceNew")
);
export const OtherIncomeNew = React.lazy(() => import("./OtherIncomeNew"));
export const OtherIncomeEdit = React.lazy(() => import("./OtherIncomeEdit"));
export const ExpenseRefundNew = React.lazy(() => import("./ExpenseRefundNew"));
export const TxnDetailColumn = React.lazy(() => import("./TxnDetailColumn"));
export const AllTransactions = React.lazy(() => import("./AllTransactions"));
export const OwnerContributionEdit = React.lazy(() =>
  import("./OwnerContributionEdit")
);
export const ExpenseNew = React.lazy(() => import("./ExpenseNew"));
export const ExpenseEdit = React.lazy(() => import("./ExpenseEdit"));
export const ExpenseRefundEdit = React.lazy(() =>
  import("./ExpenseRefundEdit")
);
export const SupplierAdvanceEdit = React.lazy(() =>
  import("./SupplierAdvanceEdit")
);
export const CustomerAdvanceEdit = React.lazy(() =>
  import("./CustomerAdvanceEdit")
);
export const InterestIncomeNew = React.lazy(() =>
  import("./InterestIncomeNew")
);
export const InterestIncomeEdit = React.lazy(() =>
  import("./InterestIncomeEdit")
);
export const SupplierAdvanceRefundNew = React.lazy(() =>
  import("./SupplierAdvanceRefundNew")
);
export const SupplierAdvanceRefundEdit = React.lazy(() =>
  import("./SupplierAdvanceRefundEdit")
);
export const CustomerAdvanceRefundNew = React.lazy(() =>
  import("./CustomerAdvanceRefundNew")
);
export const CustomerAdvanceRefundEdit = React.lazy(() =>
  import("./CustomerAdvanceRefundEdit")
);
export const DepositToAnotherAccEdit = React.lazy(() =>
  import("./DepositToAnotherAccountEdit")
);
export const DepositToAnotherAccNew = React.lazy(() =>
  import("./DepositToAnotherAccountNew")
);
export const DepositFromAnotherAccNew = React.lazy(() =>
  import("./DepositFromAnotherAccountNew")
);
export const DepositFromAnotherAccEdit = React.lazy(() =>
  import("./DepositFromAnotherAccountEdit")
);

export const Banking = React.lazy(() => import("./Banking"));
