import React from "react";
// Lazy load the additional components
export const ManualJournals = React.lazy(() => import("./ManualJournals"));
export const ChartOfAccounts = React.lazy(() => import("./ChartOfAccounts"));
export const ManualJournalsNew = React.lazy(() =>
  import("./ManualJournalsNew")
);
export const ManualJournalsEdit = React.lazy(() =>
  import("./ManualJournalsEdit")
);
export const TransactionLocking = React.lazy(() =>
  import("./TransactionLocking")
);
