// localStorageService.ts
import { AESdecrypt, AESencrypt, parseJson } from "../utils/crypto";
class LocalStorageService {
  static setItem(key, value) {
    localStorage.setItem(key, value);
  }
  static setItemAll(items = []) {
    if (!Array.isArray(items)) return;
    for (const kv of items) {
      const { key, value } = kv;
      if (key && value) {
        this.setItem(key, value);
      }
    }
  }
  static getItem(key) {
    return localStorage.getItem(key) ?? null;
  }
  // Remove an item from local storage
  static removeItem(key) {
    localStorage.removeItem(key);
  }

  // Clear all local storage items
  static clear() {
    localStorage.clear();
  }

  static getToken() {
    const jsonStr = this.getItem("__t2__");
    if (jsonStr) {
      const result = parseJson(AESdecrypt(jsonStr));
      if (result) {
        const { token, expireAt = 0 } = result;
        const currentTime = new Date().getTime();
        if (expireAt > currentTime) {
          return token;
        }
      }
    }
    LocalStorageService.clear();
    return null;
  }
  static saveToken(token) {
    const currentTime = new Date().getTime();
    const expireAt = currentTime + 10 * 60 * 60 * 1000;
    const t2 = AESencrypt(JSON.stringify({ token, expireAt }));
    LocalStorageService.setItem("__t2__", t2);
  }
}

export default LocalStorageService;
