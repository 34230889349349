import React from "react";

// Lazy load product and inventory components
export const Products = React.lazy(() => import("./Products"));
export const ProductsNew = React.lazy(() => import("./ProductsNew"));
export const InventoryAdjustments = React.lazy(() =>
  import("./InventoryAdjustments")
);
export const ProductGroups = React.lazy(() => import("./ProductGroups"));
export const ProductGroupsNew = React.lazy(() => import("./ProductGroupsNew"));
export const TransferOrders = React.lazy(() => import("./TransferOrders"));
export const TransferOrdersNew = React.lazy(() =>
  import("./TransferOrdersNew")
);
export const AdjustStock = React.lazy(() => import("./AdjustStock"));
export const OpeningStock = React.lazy(() => import("./OpeningStock"));
export const InventoryAdjustmentsNew = React.lazy(() =>
  import("./InventoryAdjustmentsNew")
);
export const ProductCategories = React.lazy(() =>
  import("./ProductCategories")
);
export const ProductUnits = React.lazy(() => import("./ProductUnits"));
export const ProductsEdit = React.lazy(() => import("./ProductsEdit"));
export const ProductGroupsEdit = React.lazy(() =>
  import("./ProductGroupsEdit")
);
export const TransferOrdersEdit = React.lazy(() =>
  import("./TransferOrdersEdit")
);
export const InventoryAdjustmentsEdit = React.lazy(() =>
  import("./InventoryAdjustmentEdit")
);
