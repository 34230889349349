import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

export default function Loading() {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 35, color: "var(--primary-color)" }}
      spin
    />
  );
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Spin indicator={antIcon} />
      <br />
      <span style={{ fontSize: 14, color: "var(--primary-color)" }}>
        Loading...
      </span>
    </div>
  );
}
