import React from "react";

// Lazy load customer components
export const Customers = React.lazy(() => import("./Customers"));
export const CustomersEdit = React.lazy(() => import("./CustomersEdit"));
export const CustomersNew = React.lazy(() => import("./CustomersNew"));

// Lazy load sales order components
export const SalesOrders = React.lazy(() => import("./SalesOrders"));
export const SalesOrdersNew = React.lazy(() => import("./SalesOrdersNew"));
export const SalesOrdersEdit = React.lazy(() => import("./SalesOrdersEdit"));

// Lazy load invoice components
export const Invoices = React.lazy(() => import("./Invoices"));
export const InvoicesNew = React.lazy(() => import("./InvoicesNew"));
export const InvoicesEdit = React.lazy(() => import("./InvoicesEdit"));

// Lazy load credit note components
export const CreditNotes = React.lazy(() => import("./CreditNotes"));
export const CreditNotesNew = React.lazy(() => import("./CreditNotesNew"));
export const CreditNotesEdit = React.lazy(() => import("./CreditNotesEdit"));

// Lazy load additional components
export const WriteOffInvoiceModal = React.lazy(() =>
  import("./WriteOffInvoiceModal")
);

export const PaymentsReceived = React.lazy(() => import("./PaymentsReceived"));

export const PaymentsReceivedEdit = React.lazy(() =>
  import("./PaymentsReceivedEdit")
);

export const PaymentsReceivedNew = React.lazy(() =>
  import("./PaymentsReceivedNew")
);
