import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export function useHistoryState(key, initialValue) {
  const navigate = useNavigate();
  const location = useLocation();
  const [rawState, rawSetState] = useState(() => {
    const value = location.state?.[key];
    return value ?? initialValue;
  });
  function setState(value) {
    navigate(location.pathname, {
      state: {
        ...location.state,
        [key]: value,
      },
      replace: true,
    });
    rawSetState(value);
  }
  return [rawState, setState];
}

export function paginateArray(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function convertTransactionType(type) {
  switch (type) {
    case "JN":
      return "Journal";
    case "IV":
      return "Invoice";
    case "CP":
      return "CustomerPayment";
    case "CN":
      return "CreditNote";
    case "CNR":
      return "CreditNoteRefund";
    case "EP":
      return "Expense";
    case "BL":
      return "Bill";
    case "SP":
      return "SupplierPayment";
    case "BP":
      return "BillPayment";
    case "IVT":
      return "Inventory";
    case "IVAQ":
      return "InventoryAdjustmentQuantity";
    case "IVAV":
      return "InventoryAdjustmentValue";
    case "IWO":
      return "InvoiceWriteOff";
    case "ACP":
      return "AdvanceCustomerPayment";
    case "ASP":
      return "AdvanceSupplierPayment";
    case "COB":
      return "CustomerOpeningBalance";
    case "SOB":
      return "SupplierOpeningBalance";
    case "OB":
      return "OpeningBalance";
    case "SC":
      return "SupplierCredit";
    case "AC":
      return "AccountTransfer";
    case "AD":
      return "AccountDeposit";
    case "OD":
      return "OwnerDrawing";
    case "OC":
      return "OwnerContribution";
    case "OI":
      return "Income";
    case "SAA":
      return "SupplierAdvanceApplied";
    case "SAR":
      return "SupplierAdvanceRefund";
    case "SCR":
      return "SupplierCreditRefund";
    case "CAA":
      return "CustomerAdvanceApplied";
    case "CAR":
      return "CustomerAdvanceRefund";
    case "POS":
      return "ProductOpeningStock";
    case "PGOS":
      return "ProductGroupOpeningStock";
    case "PCOS":
      return "ProductCompositeOpeningStock";
    case "TO":
      return "TransferOrder";
    default:
      return "";
  }
}

export function reverseConvertTransactionType(description) {
  switch (description) {
    case "Journal":
      return "JN";
    case "Invoice":
      return "IV";
    case "CustomerPayment":
      return "CP";
    case "CreditNote":
      return "CN";
    case "CreditNoteRefund":
      return "CNR";
    case "Expense":
      return "EP";
    case "Bill":
      return "BL";
    case "SupplierPayment":
      return "SP";
    case "BillPayment":
      return "BP";
    case "Inventory":
      return "IVT";
    case "InventoryAdjustment":
      return "IVA";
    case "InvoiceWriteOff":
      return "IWO";
    case "AdvanceCustomerPayment":
      return "ACP";
    case "AdvanceSupplierPayment":
      return "ASP";
    case "CustomerOpeningBalance":
      return "COB";
    case "SupplierOpeningBalance":
      return "SOB";
    case "OpeningBalance":
      return "OB";
    case "SupplierCredit":
      return "SC";
    case "TransferFromAnotherAccounts":
      return "AC";
    case "TransferToAnotherAccount":
      return "AC";
    case "DepositFromAnotherAccounts":
      return "AD";
    case "DepositToAnotherAccounts":
      return "AD";
    case "AccountDeposit":
      return "AD";
    case "OwnerDrawings":
      return "OD";
    case "OwnerContribution":
      return "OC";
    case "OtherIncome":
      return "OI";
    case "InterestIncome":
      return "OI";
    case "SupplierAdvance":
      return "ASP";
    case "CustomerAdvance":
      return "ACP";
    case "SupplierAdvanceApplied":
      return "SAA";
    case "SupplierAdvanceRefund":
      return "SAR";
    case "SupplierCreditRefund":
      return "SCR";
    case "CustomerAdvanceApplied":
      return "CAA";
    case "CustomerAdvanceRefund":
      return "CAR";
    case "ProductOpeningStock":
      return "POS";
    case "ProductGroupOpeningStock":
      return "PGOS";
    case "ProductCompositeOpeningStock":
      return "PCOS";
    case "DepositToOtherAccounts":
      return "AD";
    case "DepositFromOtherAccounts":
      return "AD";
    default:
      return "";
  }
}

export function calculateTaxAmount(
  subTotal,
  taxRate,
  isTaxInclusive,
  decimalPlaces = 2
) {
  let taxAmount = 0;
  if (isTaxInclusive) {
    taxAmount = (subTotal / (taxRate + 100)) * taxRate;
  } else {
    taxAmount = (subTotal / 100) * taxRate;
  }
  return parseFloat(taxAmount.toFixed(decimalPlaces));
}

export function calculateDiscountAmount(
  subTotal,
  discount,
  discountType,
  decimalPlaces = 2
) {
  let discountAmount = 0;
  if (discount > 0 && discountType != null) {
    if (discountType === "P") {
      discountAmount = (subTotal / 100) * discount;
    } else {
      discountAmount = discount || 0;
    }
  }
  return parseFloat(discountAmount.toFixed(decimalPlaces));
}

export function calculateItemDiscountAndTax(
  qty,
  unitRate,
  discount,
  discountType,
  taxRate,
  isTaxInclusive,
  decimalPlaces = 2
) {
  // calculate detail subtotal
  let detailAmount = qty * unitRate;
  // calculate discount amount
  let discountAmount = 0;
  if (discount > 0 && discountType != null) {
    discountAmount = calculateDiscountAmount(
      detailAmount,
      discount,
      discountType,
      decimalPlaces
    );
  }
  // calculate subtotal amount
  let totalAmount = qty * unitRate - discountAmount;
  // calculate tax amount
  let taxAmount = 0;
  if (taxRate > 0) {
    taxAmount = calculateTaxAmount(
      totalAmount,
      taxRate,
      isTaxInclusive,
      decimalPlaces
    );
  }
  return [
    parseFloat(totalAmount.toFixed(decimalPlaces)),
    discountAmount,
    taxAmount,
  ];
}

export function getModulePermissions(moduleName) {
  const modules = JSON.parse(localStorage.getItem("module")) || [];
  const moduleData = modules.find((module) => module.moduleName === moduleName);

  if (moduleData && moduleData.allowedActions) {
    return {
      read: moduleData.allowedActions.includes("read"),
      create: moduleData.allowedActions.includes("create"),
      update: moduleData.allowedActions.includes("update"),
      delete: moduleData.allowedActions.includes("delete"),
      upload: moduleData.allowedActions.includes("upload"),
      remove: moduleData.allowedActions.includes("remove"),
    };
  }
  return {
    read: false,
    create: false,
    update: false,
    delete: false,
    upload: false,
    remove: false,
  };
}

export function fetchModulesFromLocalStorage() {
  const modulesData = localStorage.getItem("module");
  return modulesData ? JSON.parse(modulesData) : [];
}

export const generatePDFBlob = (element, filename) => {
  return new Promise((resolve, reject) => {
    // Create a new iframe to isolate the PDF content from the web view
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.top = "-9999px"; // Hide iframe off-screen
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const clonedElement = element.cloneNode(true); // Clone the element to avoid modifying the original

    // Add the 'template-pdf' class to the cloned element for PDF-specific styles
    clonedElement.classList.add("template-pdf");

    // Append the cloned element to the iframe's document body
    iframeDoc.body.appendChild(clonedElement);

    const opt = {
      margin: [1, 0.2, 1, 0.2],
      filename: filename || "file-name",
      image: { type: "png", quality: 1 },
      html2canvas: { scale: 4, useCORS: true },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation: "portrait",
        precision: 20,
        putOnlyUsedFonts: true,
        pageBreak: { after: "section", mode: ["avoid-all", "css", "legacy"] },
      },
    };

    html2pdf()
      .from(clonedElement) // Use cloned element inside iframe
      .set(opt)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor(130);
          pdf.text(
            `${i}/${totalPages}`,
            pdf.internal.pageSize.getWidth() / 2, // Center text
            pdf.internal.pageSize.getHeight() - 0.5,
            { align: "center" }
          );
        }

        // Output the PDF as a Blob
        const pdfBlob = pdf.output("blob");
        const url = URL.createObjectURL(pdfBlob);

        // Clean up: remove the PDF-specific class and iframe after generation
        clonedElement.classList.remove("template-pdf");
        document.body.removeChild(iframe);

        resolve(url);
      })
      .catch((err) => {
        // Clean up: remove the PDF-specific class and iframe on error
        clonedElement.classList.remove("template-pdf");
        document.body.removeChild(iframe);
        reject(err);
      });
  });
};

export const generatePDF = (element, filename, orientation = "landscape") => {
  return new Promise((resolve, reject) => {
    // create a new iframe to isolate the PDF content from the web view
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.top = "-9999px"; // Hide iframe off-screen
    document.body.appendChild(iframe);

    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    const clonedElement = element.cloneNode(true); // Clone the element to avoid modifying the original

    // add the 'report-pdf' class to the cloned element for PDF-specific styles
    clonedElement.classList.add("report-pdf");

    // convert all <a> tags in the cloned element to plain text
    const links = clonedElement.querySelectorAll("a");
    links.forEach((link) => {
      const span = document.createElement("span");
      span.textContent = link.textContent;
      span.style.color = "var(--text-color)"; // Maintain primary color
      link.parentNode.replaceChild(span, link);
    });

    // append the cloned element to the iframe
    iframeDoc.body.appendChild(clonedElement);

    const opt = {
      margin: [0.7, 0.5, 1, 0.5],
      filename: filename || "filename",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: {
        unit: "in",
        format: "a4",
        orientation,
        precision: 20,
        putOnlyUsedFonts: true,
        pageBreak: { after: "section", mode: ["avoid-all", "css", "legacy"] },
      },
    };

    // use the iframe's document to generate the PDF
    html2pdf()
      .from(clonedElement) // Use cloned element for PDF
      .set(opt)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages();

        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.setTextColor(130);
          pdf.text(
            `${i}/${totalPages}`,
            pdf.internal.pageSize.getWidth() / 2, // calculate the center of the page
            pdf.internal.pageSize.getHeight() - 0.5,
            { align: "center" }
          );
        }

        // save the PDF
        pdf.save(filename || "filename");

        // clean up iframe after PDF is generated
        document.body.removeChild(iframe);
        resolve();
      })
      .catch((err) => {
        // clean up iframe on error
        document.body.removeChild(iframe);
        reject(err);
      });
  });
};
