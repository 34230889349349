import React from "react";

// Lazy load bills, expenses, and suppliers components
export const Bills = React.lazy(() => import("./Bills"));
export const Expenses = React.lazy(() => import("./Expenses"));
export const ExpensesNew = React.lazy(() => import("./ExpensesNew"));
export const ExpensesEdit = React.lazy(() => import("./ExpensesEdit"));
export const PaymentsMade = React.lazy(() => import("./PaymentsMade"));
export const PurchaseOrders = React.lazy(() => import("./PurchaseOrders"));
export const SupplierCredits = React.lazy(() => import("./SupplierCredits"));
export const Suppliers = React.lazy(() => import("./Suppliers"));
export const PurchaseOrdersNew = React.lazy(() =>
  import("./PurchaseOrdersNew")
);
export const SuppliersNew = React.lazy(() => import("./SuppliersNew"));
export const SuppliersEdit = React.lazy(() => import("./SuppliersEdit"));
export const BillsNew = React.lazy(() => import("./BillsNew"));
export const BillsEdit = React.lazy(() => import("./BillsEdit"));
export const SupplierCreditsNew = React.lazy(() =>
  import("./SupplierCreditsNew")
);
export const SupplierCreditsEdit = React.lazy(() =>
  import("./SupplierCreditsEdit")
);
export const PurchaseOrdersEdit = React.lazy(() =>
  import("./PurchaseOrdersEdit")
);
export const PaymentsMadeNew = React.lazy(() => import("./PaymentsMadeNew"));
export const BillApplyCreditModal = React.lazy(() =>
  import("./BillApplyCreditModal")
);
export const PaymentsMadeEdit = React.lazy(() => import("./PaymentsMadeEdit"));
